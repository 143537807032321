import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
    <Layout sermonSrc="https://www.youtube.com/embed/8jU8K0a8htQ">
        <SEO title="Fear on the Wrong Side - Crossing Rivers" />
    </Layout>
)

export default SermonPost
